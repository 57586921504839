import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import { ClientTable } from "vue-tables-2";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueSidebarMenu from "vue-sidebar-menu";
import Toasted from "vue-toasted";
import VueCharts from "vue-chartjs";
import VueHtmlToPaper from "vue-html-to-paper";
import vSelect from "vue-select";

// import store from "./store";

import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-select/src/scss/vue-select.scss";

Vue.config.productionTip = false;

Vue.use(ClientTable);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons);
Vue.use(VueSidebarMenu);
Vue.use(Toasted);
Vue.use(VueCharts);
Vue.use(VueHtmlToPaper);
Vue.component("v-select", vSelect);

new Vue({
    router,
    // store,
    render: (h) => h(App),
}).$mount("#app");
